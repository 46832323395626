.wallet {
    --bg-color: #137f97;
    --bg-color-light: #24acca;
    --text-color-hover: #fff;
    --box-shadow-color: #0A5364;
  }
  
  .card_wallet_wallet {
    width: 220px;
    height: 321px;
    background: #fff;
    border-top-right-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
    transition: all 0.3s ease-out;
    text-decoration: none;
  }
  
 
  
  .card_wallet p {
    font-size: 17px;
    font-weight: bold;
    color: #0A5364; /* Initial color */
    margin-top: 30px;
    z-index: 1000;
    transition: color 0.3s ease-out; /* Transition for color change */
}

.card_wallet p:hover {
    color: #DBB14B; /* Color changes to purple when hovered */
}

.circle_wallet {
    width: 131px;
    height: 131px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid var(--bg-color);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease-out;
}

