
.container {
  display: flex;
  flex-direction: row; /* Asegura que los elementos se alineen en fila */
  overflow-x: auto; /* Habilita el desplazamiento horizontal */
  padding: 24px;
  gap: 20px; /* Espacio entre las tarjetas */
  scroll-snap-type: x mandatory; /* Opcional, para un desplazamiento más controlado */
}

.cardBox {
    min-width: 250px; /* Modifica según la necesidad de ancho de tus tarjetas */
    height: 350px;
    position: relative;
    display: grid;
    place-items: center;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px,
      rgba(0, 0, 0, 0.5) 0px 2px 25px 0px;
}
  
.card_ {
  display: flex;
  justify-content_: center; /* Centra los elementos hijos verticalmente */
  align-items: center; /* Centra los elementos hijos horizontalmente */
  flex-direction: column; /* Orienta los elementos hijos en una columna */
  width: 100%; /* Ajusta según necesidad */
  height: 95%; /* Ajusta según necesidad */
  background: #0A5364;
  border-radius: 20px;
  text-align: center;
  color: #ffffff;
  overflow: hidden;
  padding: 20px;
  cursor: pointer;
  transition: 0.3s ease-in-out; /* Asegura una transición suave para todos los elementos */
 
}
  .card_ .h4 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: 800;
    pointer-events: none;
    opacity: .5;
  }
  
  .card_ .content_ .h_3 {
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 10px;
  }
  
  .card_ .content_ p {
    font-size: 14px;
    line-height: 1.4em;
  }
  
  .card_ .content_ {
    transform: translateY(100%);
    opacity: 0;
    transition: 0.3s ease-in-out;
  }
  
  .card_:hover .content_ {
    transform: translateY(0);
    opacity: 1;
  }
  
  .card_:hover .h4 {
    opacity: 0;
  }
  
  .cardBox::before {
    content_: "";
    position: absolute;
    width: 40%;
    height: 150%;
    background: #40E0D0;
    background: -webkit-linear-gradient(to right, #FF0080, #FF8C00, #40E0D0);
    background: linear-gradient(to right, #FF0080, #FF8C00, #40E0D0);
    transform-origin: center;
    animation: glowing_401 5s linear infinite;
  }
  
  @keyframes glowing_401 {
    0% {
      transform: rotate(0);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  .content_ {
    text-align: left; /* Alinea el texto a la izquierda */
  }
  
  .h_3 {
    font-size: 24px; /* Ajusta el tamaño del texto según necesites */
    margin-bottom: 16px; /* Agrega espacio debajo del título */
    text-transform: uppercase; /* Convierte el texto en mayúsculas */
  }
  
  .card__describe {
    font-size: 24px; /* Hace el texto más grande */
    list-style-position: inside; /* Asegura que los marcadores de lista estén dentro */
    padding-left: 0; /* Elimina el padding izquierdo predeterminado */
    text-transform: uppercase; /* Convierte el texto de los subtemas en mayúsculas */
  }
  
  .card__describe li {
    margin-bottom: 10px; /* Agrega espacio entre los elementos de la lista */
    border-bottom: 1px solid #ccc; /* Añade una línea separadora */
    padding-bottom: 10px; /* Espacio entre el texto y la línea separadora */
  }
  
  .card_:hover .card__title {
    transform: translateY(100%); /* Mueve el título hacia arriba para que desaparezca */
    opacity: 0; /* Hace el título completamente transparente */
    transition: 0.3s ease-in-out; /* Asegura una transición suave */
}

.content_ {
    text-align: left; /* Alinea el texto a la izquierda */
    transform: translateY(100%); /* Inicialmente oculta el contenido debajo */
    opacity: 0; /* Inicia con el contenido invisible */
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Transiciones suaves para transformación y opacidad */
    justify-content_: center;
}

.card_:hover .content_ {
    transform: translateY(0); /* Mueve el contenido a su posición original */
    opacity: 1; /* Hace el contenido completamente visible */
}

.h_3, .li {
    font-size: 20px; /* Ajusta esta propiedad para cambiar el tamaño del texto */
    text-transform: uppercase; /* Transforma el texto a mayúsculas */
    font-weight: 800; /* Opcional: Hace el texto más grueso */
}

.li {
    border-bottom: 1px solid #ccc; /* Añade una línea separadora entre los subtemas */
    padding: 10px 0; /* Agrega relleno arriba y abajo para espacio */
    margin-bottom: 10px; /* Espacio entre los subtemas */
    border-radius: 10px; /* Redondea las esquinas de la línea si es necesario */
}

.card__title {
  font-size: 20px; /* Ajusta el tamaño del texto según necesites */
  text-transform: uppercase; /* Convierte el texto en mayúsculas */
  font-weight: 300;
  margin-top: 150px;

  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Transiciones suaves para transformación y opacidad */
  align-self: center; /* Asegura que el título se centre incluso si los elementos hermanos tienen diferentes tamaños */
}

.card_ .clicked {
  align-items: flex-start; /* Alinea el inicio del contenido de la tarjeta */
  height: 300; /* Permite que la tarjeta se expanda según el contenido */
}

.card_ .clicked .card__title {
  display: none; /* Oculta el título cuando la tarjeta está en estado 'clicked' */
}

.content_ .show {
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}