:root {

    /*Bootstrap*/
  --azul1: #007aff;
  --azul-intenso: #0275d8; /* Color azul intenso */
  --azul-vibrante:#025aa5;
  --azul-profundo: #014c8c; /* Color azul profundo */
  --azul-profundo2:#01549b ;
  --color-cielo: #5bc0de;
  --azul-cielo:#8fcafe;
  --azul-cielo2:#33fd00;
  --color-brillante: #31b0d5;
  --color-brillante2: #2aabd2;
  --azul-palido:#d9edf7;
  --azul-celeste:#c4e3f3;
  --azul-claro:#49fc02;
  --azul-oscuro:#31708f;

  --azul-palido2: #bcdff1; /* Tono pálido de azul */
  --azul-palido3:#a6d5ec;
  --azul-oscuro2: #245269; /* Tono oscuro de azul */
  --amarillo-claro2: #f7ecb5; /* Tono claro de amarillo */
  --amarillo-palido2: #66512c; /* Tono pálido de amarillo */
  --marron2: #66512c; /* Marrón */
  --rojo-palido2: #e4b9b9; /* Tono pálido de rojo */
  --rojo-rosa-claro2: #e4b9b9; /* Tono claro de rojo/rosa */
  --rojos-oscuro3: #843534; /* Segundo tono oscuro de rojo */


  --amarillo1: #CFA647;
  --amarillo2: #CFA647;
  --amarillo-claro: #fcf8e3; /* Color amarillo claro */
  --amarillo-palido:#faf2cc;

  --negro: #000;
  --negro2:#000000;
  --blanco: #000000;

  --calido:#fdf7f7;

  --gris-claro: #ddd; /* Color gris claro */
  --gris-claro4:#ccc;
  --gris-claro2: #eceeef; /* Color gris claro 2 */
  --gris-claro3: #f7f7f9; /* Color gris claro 3 */
  --gris-claro5:#e6e6e6;
  --gris-claro6:#d0d5d8;
  --gris-claro7:#ebebeb;
  --gris-medio:#adadad;
  --gris-oscuro: #292b2c; /* Color gris oscuro */
  --gris-medio: #636c72; /* Color gris medio */
  --gris-medio3:#707070;
  --gris-oscuro2:#464a4c ;
  --gris-oscuro3:#1d1e1f;
  --gris-oscuro4:#4b5257;
  --gris-oscuro5:#999;
  --gris-plata:#c0c0c0;

  --rojo-oscuro: #bd4147; /* Color rojo oscuro */
  --rojo-intenso:#d9534f;
  --rojo--palido:#f2dede;
  --rojo-rosa-claro:#ebcccc;
  --rojo-brillante:#c9302c;
  --rojo-brillante2:#c12e2a;
  --rojos-oscuro2:#a94442;
  --rojo-brillante3:#C3323A;



  --anaranjado-claro:#f0ad4e;
  --anaranjado-medio:#ec971f;
  --anaranjado-oscuro:#eb9316;

  --marron:#8a6d3b;

  --verde-palido:#dff0d8;
  --verde-menta:#d0e9c6;
  --verde-claro:#5cb85c;
  --verde-claro2:#c1e2b3;
  --verde-palido2:#eaf6ea;
  --verde-oscuro-brillante:#449d44;
  --verde-oscuro-brillante2:#419641;
  --verde-oscuro:#3c763d;
  --verde-oscuro2:#2b542c;



  --color-transparente-50: rgba(0, 0, 0, 0.5); /* Color negro con 50% de transparencia */
  --color-transparente-70: rgba(0, 0, 0, 0.7); /* Color negro con 50% de transparencia */
  --color-transparente-30: rgba(0, 0, 0, 0.3); /* Color negro con 50% de transparencia */
  --color-transparente-25:  rgba(0, 0, 0, 0.25); /* Color negro con transparencia */
  --color-transparente-20:  rgba(0, 0, 0, 0.20); /* Color negro con transparencia */
  --negro-transparente-15: rgba(0, 0, 0, 0.15); /* Negro con 15% de transparencia */
  --negro-transparente-90: rgba(0, 0, 0, 0.9); /* Negro con 15% de transparencia */
  --color-transparente-10: rgba(0, 0, 0, 0.1); /* Color negro con 10% de transparencia */
  --color-transparente-12: rgba(0, 0, 0, 0.125); /* Color negro con 10% de transparencia */
  --negro-transparente-05: rgba(0, 0, 0, 0.05); /* Negro con 5% de transparencia */
  --negro-transparente-075: rgba(0, 0, 0, 0.075); /* Negro con 7.5% de transparencia */


  --blanco-transparente-50:rgba(255, 255, 255, 0.5);
  --blanco-transparente-75:rgba(255, 255, 255, 0.75);
  --blanco-transparente-65:rgba(255, 255, 255, 0.65);
  --blanco-transparente-25:rgba(255, 255, 255, 0.25);
  --blanco-transparente-20:rgba(255, 255, 255, 0.20);
  --blanco-transparente-13:rgba(255, 255, 255, 0.13);
  --blanco-transparente-0:rgba(255, 255, 255, 0);
  --blanco-transparente-100:rgba(255, 255, 255, 1);
  --blanco-transparente-30:rgba(255, 255, 255, 0.30);
  --blanco-transparente-05:rgba(255, 255, 255, 0.05);
  --blanco-transparente-15:rgba(255, 255, 255, 0.15);
  --blanco-transparente-10:rgba(255, 255, 255, 0.10);
  --blanco-transparente-53:rgba(255, 255, 255, 0.53);
  --blanco-transparente-8:rgba(255, 255, 255, 0.08);

  --azul-transparente-50:rgba(2, 117, 216, 0.5);
  --azul-transparente-25:rgba(2, 117, 216, 0.25);
  --azul-transparente-claro-50:rgba(91, 192, 222, 0.5);

  --gris-transparente-50:rgba(204, 204, 204, 0.5);

  --verder-transparente-50:rgba(92, 184, 92, 0.5);

  --anaranjado-transparente-50:rgba(240, 173, 78, 0.5);

  --rojo-transparente-50:rgba(217, 83, 79, 0.5);


  --transparente: rgba(0, 0, 0, 0); /* Color transparente */
  
  --gradiente-inicio: rgba(
    0,
    0,
    0,
    0.5
  ); /* Color inicial del gradiente con transparencia */
  --gradiente-fin: rgba(
    0,
    0,
    0,
    0
  ); /* Color final del gradiente transparente */




}
