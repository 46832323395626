.certificate-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* O la altura que prefieras */
    margin-top: 5%;
  }
  
  .certificate-image {
    max-width: 100%;
    max-height: 100%;
    /* Añade cualquier otro estilo que necesites */
  }
  