

/* Estilos Base */
* {
    box-sizing: border-box; /* Asegura que padding y border están incluidos en el ancho total y alto de los elementos */
}

.dataRow {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  
  ._card-info {
    margin-bottom: 10px;
    text-align: right;
    justify-content: center;
  }

 .card-crypto {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-content: space-between; /* Cambiado para distribuir el espacio */
    padding: 5px;
    width: 100%; /* Ajustado para ocupar todo el ancho del contenedor */
    border-top: 1px solid #ccc; /* Borde solo en la parte superior */
    border-bottom: 1px solid #ccc; /* Borde solo en la parte inferior */
    border-radius: 5px;
  }

  .cardContent-info {
    padding: 10px;
    justify-content: center;
  }

  .featuredMoney {
    font-size: 14px;

  }
  
  .featuredTitle {
    font-size: 14px;
    margin-top: 5px;
  }

  .card-crypto {
    position: relative;
    overflow: hidden; /* Evita que la línea se muestre fuera de la carta */
  }
  
  @keyframes cambiarColor {
    0% {
      color: #1BFF00; /* Verde */
    }
    25% {
      color: #00AEFF; /* Azul */
    }
    50% {
      color: #FF2D00; /* Rojo */
    }
    75% {
      color: #FF00F6; /* Magenta */
    }
    100% {
      color: #1BFF00; /* Vuelve al Verde */
    }
  }
  

  
  .item {
    flex: 1;
    text-align: center; /* Asegura que el texto dentro de los elementos esté centrado */
    font-size: 1rem; /* Reduce el tamaño de fuente para ajustar el contenido */
    font-weight: bold;
  }
  


.featuredMoneyContainer{
    font-size: 40px;
}









/* Ajustes específicos para .purchaseColumn para pantallas grandes */
.purchaseColumn-info {
    display: flex; /* Cambia grid por flex para un diseño de fila */
    flex-wrap: wrap; /* Permite que los elementos se envuelvan en líneas adicionales según sea necesario */
    gap: 20px; /* Mantiene el espacio entre elementos */
    justify-content: center;
}

.purchaseColumn-info .featuredItem {
    
    background: rgba(255, 255, 255, 0.7); /* Fondo claro para el efecto glass, ajusta la opacidad según necesites */
    backdrop-filter: blur(10px); /* Desenfoque para el fondo, creando el efecto glass */
    border-radius: 10px; /* Bordes redondeados para el diseño de los elementos */
    justify-content: center;
    align-items: center;
}

.purchaseColumn-info .featuredItem:hover {
    transform: translateY(-5px); /* Mueve el elemento hacia arriba al pasar el cursor */
}


/* Estilos para elementos destacados */
.featuredItem, .featuredItemB, .featuredItemC, .featuredItemD {
    cursor: pointer;
    color: white;

}

/* Estilos para texto y contenedores internos */
.featuredTitle, .featuredMoney, .featuredmoneyRate, .featureSub {
    word-spacing: 2px;
    letter-spacing: 1px;
}


.featuredMoneyCircle {
    font-size: 24px; /* Aumenta el tamaño de la fuente para hacer el número más grande */
    margin-bottom: 10px; /* Agrega espacio debajo del número */
    display: flex; /* Esto asegura que el contenido esté centrado si es necesario */
    align-items: center; /* Centra el contenido verticalmente */
    justify-content: center; /* Centra el contenido horizontalmente */
}

.featuredTitle {
    font-size: 20px; /* Ajusta esto según necesites */
    margin-top: 10px; /* Agrega espacio arriba del texto */
}



.purchaseColumn-info {
    display: flex;
    justify-content: space-between;
    padding: 0 20px; /* Margen izquierdo y derecho */
}



._card {
    width: 190px;
    height: 254px;
    border-radius: 15px;
    border: 1px #000;
    gap: 20px;

   }
   

   
   ._card span {
    font-weight: 600;
    color: white;
    text-align: center;
    display: block;
    padding-top: 10px;
    font-size: 16px;
   }
   
   ._card .job {
    font-weight: 400;
    color: white;
    display: block;
    text-align: center;
    padding-top: 3px;
    font-size: 12px;
   }
   
   ._card .img {
    width: 120px; /* Ajustado para que el ancho y el alto sean iguales */
    height: 120px; /* Ajustado para que el ancho y el alto sean iguales */
    background: #dbb14b;
    border-radius:20%; /* Ajustado para crear un círculo perfecto */
    margin: auto;
    margin-top: 25px;
    display: flex; /* Asegura que funcione justify-content y align-items */
    justify-content: center;
    align-items: center;
}
   ._card button {
    padding: 8px 25px;
    display: block;
    margin: auto;
    border-radius: 8px;
    margin-top: 30px;
    background: #C89D38;
    color: white;
    font-weight: 600;
   }
   
   ._card button:hover {
    background: #8a681b;
    color:#000
   }



* {
    box-sizing: border-box;
}







  .featuredMoneyContainer, .featuredTitle {
    margin: 0; /* Elimina los márgenes para un look más compacto y formal */
  }
  
  /* Ajustes adicionales para asegurar que la presentación sea formal y compacta */
  .featuredMoneyContainer-future, .featuredMoneyContainer, .featuredTitle {
    display: flex;
    align-items: center; /* Centra el contenido verticalmente */
    justify-content: center; /* Centra el contenido horizontalmente */
  }

.featured {
    padding: 20px;
    background-color: #ffffff; /* Fondo blanco para el área principal */
    max-width: 100%; /* Ajusta esto según el ancho máximo deseado */
    margin: auto; /* Centra los contenedores en la página */
    justify-content: center;
    align-items: center;
}

/* Ajustes específicos para la sección de compras y estilos de tarjetas */
.purchaseColumn-info, ._card {
    background-color: white; /* Fondo blanco */
    color: #000; /* Texto negro */
    border-radius: 0; /* Bordes cuadrados */
}



.container_featured {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: #ffffff;
  width: 98%; /* Ajusta el ancho al 98% del contenedor padre */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Agrega sombra ligera para un efecto sutil */
  margin: auto; /* Centra el contenedor si es menor que el espacio disponible */
  border-radius: 10px; /* Suaviza las esquinas con un radio de borde de 10px */
}


/* Agrega estilos adicionales según tus necesidades */

.welcomeCard {
    background-color: #ffffff; /* Color de fondo gris */
    padding: 20px; /* Espaciado interno para separar el contenido de los bordes */
    text-align: left; /* Centrar el texto horizontalmente */
    width: 40%;
    margin: 20px 0; /* Margen arriba y abajo para separar de otros elementos */
}

.welcomeMessage {
    color: #0A5364; /* Color de texto oscuro para contraste */
    font-size: 24px; /* Tamaño de fuente para el mensaje de bienvenida */
    margin-bottom: 15px; /* Espacio debajo del mensaje de bienvenida */
    font-weight: bold;
    text-align: left; /* Alinea el texto a la izquierda */
}


.userWallet {
    color: #0A5364; /* Color de texto más claro para la dirección de la wallet */
    font-size: 16px; /* Tamaño de fuente para la dirección de la wallet */
    word-break: break-all; /* Asegura que la dirección de la wallet no desborde el contenedor */
    margin-top: 10px;
    font-weight: bold;
}




.cards_in {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.cards_in .red {
  background-color: #f43f5e;
}


.cards_in .card_in {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100px;
  width: 250px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition: 400ms;
}

.cards_in .card_in p.tip {
  font-size: 2em;
  font-weight: 700;
}

.cards_in .card_in p.second-text {
  font-size: .7em;
}

.cards_in .card_in:hover {
  transform: scale(1.1, 1.1);
}

.cards_in:hover > .card_in:not(:hover) {
  filter: blur(10px);
  transform: scale(0.9, 0.9);
}



