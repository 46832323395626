


#chart_ {
  width: 98%;
  margin: 2rem auto;
  background-color: #ffffff;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-radius: 5px;
}

#timeline-chart_ {
  margin: 0 auto;
  transition: 0.5s ease;
  opacity: 0;
  transform: scale(0.95);
}

.loader {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 2019;
}

.loader span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader span .loader-part {
  width: 1.5rem;
  height: 1.5rem;
  background-color: #4a919c;
  border-radius: 50%;
  animation: loader 1s ease infinite;
}

@keyframes loader {
  0% {
    transform: skewY(0) translate(0);
  }
  33% {
    transform: skewY(10deg) translate(4rem);
  }
  66% {
    transform: skewY(-10deg) translate(-4rem);
  }
  100% {
    transform: skewY(0) translate(0);
  }
}
