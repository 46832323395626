/* fonts.css */

:root {
    --font-primary: 'Open Sans', sans-serif;
    --font-secondary: 'Open Sans', sans-serif; /* Puedes agregar más fuentes según sea necesario */
    --font-inherit   : inherit;
    --font-manrope   : 'Open Sans', sans-serif;
    --font-icomoon: 'icomoon' ;
    --font-azeret: 'Open Sans', sans-serif;
    --font-poppins: 'Open Sans', sans-serif;
  }