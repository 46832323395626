:root {
  --circle-radius: 300px; /* Radio del círculo */
  --translate-x: -0%; /* Valor de translate en el eje X */
  --translate-y: -0%; /* Valor de translate en el eje Y */
}

.protocol-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  margin-top: 15%;
}

.circle {
  width: 600px;
  height: 600px;
  border-radius: 50%;
  border: 1px solid #ccc;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.protocol {
  position: absolute;
  cursor: pointer;
  text-align: center;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #C89D38; /* Fondo azul */
  transition: background-color 0.3s ease, color 0.3s ease;
  transform: translate(var(--translate-x), var(--translate-y));
}

.protocol h3 {
  font-size: 12px;
  margin: 0;
  padding: 10px;
  color: #000; /* Color del texto blanco */
}

.protocol.active {
  background-color: blue; /* Fondo dorado */
}

.protocol:nth-child(1) { transform: translate(var(--translate-x), var(--translate-y)) rotate(0deg) translate(var(--circle-radius)) rotate(0deg); }
.protocol:nth-child(2) { transform: translate(var(--translate-x), var(--translate-y)) rotate(25.71deg) translate(var(--circle-radius)) rotate(-25.71deg); }
.protocol:nth-child(3) { transform: translate(var(--translate-x), var(--translate-y)) rotate(51.42deg) translate(var(--circle-radius)) rotate(-51.42deg); }
.protocol:nth-child(4) { transform: translate(var(--translate-x), var(--translate-y)) rotate(77.13deg) translate(var(--circle-radius)) rotate(-77.13deg); }
.protocol:nth-child(5) { transform: translate(var(--translate-x), var(--translate-y)) rotate(102.84deg) translate(var(--circle-radius)) rotate(-102.84deg); }
.protocol:nth-child(6) { transform: translate(var(--translate-x), var(--translate-y)) rotate(128.55deg) translate(var(--circle-radius)) rotate(-128.55deg); }
.protocol:nth-child(7) { transform: translate(var(--translate-x), var(--translate-y)) rotate(154.26deg) translate(var(--circle-radius)) rotate(-154.26deg); }
.protocol:nth-child(8) { transform: translate(var(--translate-x), var(--translate-y)) rotate(179.97deg) translate(var(--circle-radius)) rotate(-179.97deg); }
.protocol:nth-child(9) { transform: translate(var(--translate-x), var(--translate-y)) rotate(205.68deg) translate(var(--circle-radius)) rotate(-205.68deg); }
.protocol:nth-child(10) { transform: translate(var(--translate-x), var(--translate-y)) rotate(231.39deg) translate(var(--circle-radius)) rotate(-231.39deg); }
.protocol:nth-child(11) { transform: translate(var(--translate-x), var(--translate-y)) rotate(257.1deg) translate(var(--circle-radius)) rotate(-257.1deg); }
.protocol:nth-child(12) { transform: translate(var(--translate-x), var(--translate-y)) rotate(282.81deg) translate(var(--circle-radius)) rotate(-282.81deg); }
.protocol:nth-child(13) { transform: translate(var(--translate-x), var(--translate-y)) rotate(308.52deg) translate(var(--circle-radius)) rotate(-308.52deg); }
.protocol:nth-child(14) { transform: translate(var(--translate-x), var(--translate-y)) rotate(334.23deg) translate(var(--circle-radius)) rotate(-334.23deg); }

.center-content {
  text-align: center;
  padding: 20px;
  width: 300px;
}

.definition {
  margin-top: 10px;
}
