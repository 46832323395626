.profile-container{
    margin-top: 10%;
}
.purchaseColumn-buy {
    margin-top: 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* Asegura un espacio entre las columnas */
}

.card-column {
    flex-basis: calc(33.333% - 20px); /* Ajusta tres tarjetas por fila con algo de espacio entre ellas */
    margin-bottom: 20px; /* Espacio entre filas de tarjetas */
    background: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px; /* Bordes redondeados para las tarjetas */
    overflow: hidden; /* Asegura que todo quede dentro de los bordes redondeados */
}

.card-section {
    padding: 20px;
}

.card-title {
    margin: 0;
    color: #d4ae42; /* Color del título */
    text-align: center;
}

.card-details {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
}

.circle-profile {
    position: relative;
    background-color: #0A5364;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.circle-profile:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 4px solid transparent; /* Tamaño del borde */
    border-top-color: #D4AE42; /* Color del borde giratorio */
    border-radius: 50%;
    animation: spin 2s linear infinite; /* Velocidad de la animación */
}

.circle-text {
    position: relative; /* Asegúrate de que el texto se mantenga fijo y encima del borde giratorio */
    z-index: 10; /* Eleva el texto por encima del borde giratorio */
    text-align: center;
    width: 90%;
    line-height: 1.2;
    color:#FFF;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


.detail-card {
    margin-bottom: 10px; /* Espacio entre detalles */
    padding: 10px;
    background-color: #f4f4f4; /* Fondo para los detalles */
    color: #d4ae42; /* Color del texto */
    border-radius: 2px;
}

.blue { background-color: #add8e6; }
.gray { background-color: #add8e6; }
.dark-gray { background-color: #add8e6; }

.copyCode {
    background-color: #d4ae42; /* Fondo del botón */
    color: white; /* Texto del botón */
    border: none;
    padding: 10px 20px;
    border-radius: 5px; /* Bordes redondeados para el botón */
    cursor: pointer; /* Cambia el cursor a una mano cuando se pasa por encima */
    margin-top: 10px;
    display: block; /* Asegura que el botón ocupe todo el ancho disponible */
    width: 100%;
}

.no-purchases {
    text-align: center;
    width: 100%;
    padding: 20px;
    background-color: #f2f2f2; /* Fondo cuando no hay compras */
    color: #333; /* Texto cuando no hay compras */
}

.bg-text1, .bg-text2, .detail-card {
    white-space: normal; /* Asegura que el texto se ajuste y no se desborde */
    word-wrap: break-word; /* Asegura que las palabras largas se partan correctamente */
    font-size: large;
    font-weight: bold;
}

.userAddress span {
    font-size: 1.5em; /* Ajusta el tamaño del texto */
    color: #d4af37; /* Esto es un tono dorado */
    font-weight: bold; /* Hace el texto más grueso */
    padding: 5px; /* Agrega un poco de espacio alrededor del texto */
    display: inline-block; /* Esto es para aplicar padding correctamente */
}
