/* quiz.css */

.container-quiz {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
}

.card-quiz {
  background-color: #0A5364;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  width: 850px; /* Ancho fijo */
  height: 400px; /* Alto fijo */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Para espaciar el contenido */
  color: white;
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.list-group {
  flex-grow: 1;
  overflow-y: auto; /* Añadir scroll si el contenido es demasiado grande */
  margin-bottom: 20px;
  padding: 0;
}

.list-group-item {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #e9ecef;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.list-group-item.selected {
  background-color: #007bff;
  color: white;
}

.list-group-item:hover {
  background-color: #cfe2f3;
}

input[type="radio"] {
  margin-right: 10px;
}

.btn {
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-success {
  background-color: #28a745;
  color: white;
}

.btn-success:hover {
  background-color: #218838;
}

.mt-3 {
  margin-top: 20px;
}

.me-2 {
  margin-right: 10px;
}
