.my-layout-container .registration-card {
  margin-bottom: 10%; /* Agrega un margen en la parte inferior */
}

.my-layout-container {
  margin-top: 5%; /* Ajusta este valor según sea necesario */
}

/* RegisterForm.css */
.form-container label {
  color: black; /* Asegura que el texto de las etiquetas sea negro */
}

.form-container .form-input {
  border: 2px solid #C89D38; /* Contorno dorado */
  border-radius: 8px; /* Bordes redondeados */
  padding: 8px; /* Espaciado interior */
  margin-bottom: 10px; /* Margen inferior */
  color: black; /* Color del texto dentro del input */
}

.form-container .form-input:focus {
  border-color: #FFD700; /* Cambia el color del borde a un dorado más brillante cuando el campo está enfocado */
  outline: none; /* Elimina el contorno predeterminado para evitar dobles bordes */
  box-shadow: 0 0 8px #0A5364; /* Agrega un resplandor alrededor del campo para mejor visibilidad */
}

.form-container .form-button-invite {
  background-color: #C89D38; /* Fondo dorado */
  color: white; /* Letras blancas */
  border: none; /* Sin borde */
  border-radius: 8px; /* Bordes redondeados */
  padding: 10px 20px; /* Espaciado interior */
  cursor: pointer; /* Cursor en forma de mano al pasar sobre el botón */
  font-weight: bold; /* Texto en negrita */
}

.form-container .form-button:disabled {
  background-color: rgb(131, 131, 131); /* Fondo gris para el botón deshabilitado */
  color:aliceblue;
  border-radius:20px
}

.form-container .message {
  color: black; /* Asegurando que el mensaje esté en negro */
}

/* Actualizaciones en tu archivo CSS */

.form-container .form-button-yellow {
  background-color: #C89D38; /* Fondo amarillo */
  color: black; /* Letras negras */
  border: none; /* Sin borde */
  border-radius: 8px; /* Bordes redondeados */
  padding: 10px 20px; /* Espaciado interior */
  cursor: pointer; /* Cursor en forma de mano al pasar sobre el botón */
  font-weight: bold; /* Texto en negrita */
  transition: background-color 0.3s; /* Transición suave del color de fondo */
}

.form-container .form-button-yellow:active {
  background-color: #f5be3d; /* Fondo dorado cuando se presiona */
}

.form-container .form-button-yellow:disabled {
  background-color: rgb(131, 131, 131); /* Fondo gris para el botón deshabilitado */
  color: aliceblue;
  border-radius: 20px;
}

.message {
  color: white;
  padding: 15px;
  margin-top: 20px; /* Asegura un espacio después del formulario */
  margin-bottom: 20px; /* Espacio antes del pie de página */
  border-radius: 5px;
  text-align: center; /* Asegura que el texto esté centrado */
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid white;
  width: 200%; /* Utiliza todo el ancho disponible */
  box-sizing: border-box;
}

.message-success, .message-error {
  display: flex; /* Usa flexbox para centrar el contenido */
  justify-content: center; /* Centra el contenido horizontalmente */
  align-items: center; /* Centra el contenido verticalmente */
  text-align: center; /* Asegura que el texto esté centrado */
}

.message-success {
  background-color: rgba(0, 128, 0, 0.5); /* Fondo verde semitransparente para éxito */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill="white" d="M7.629 15.179L2.864 10.414l1.414-1.414L7.629 12.35l8.093-8.093 1.414 1.414-9.507 9.508z"/></svg>');
  background-repeat: no-repeat;
  background-position: 10px center; /* Ajusta la posición del ícono al centro izquierdo */
  background-size: 24px 24px; /* Ajusta el tamaño del ícono */
}

.message-error {
  background-color: rgba(255, 0, 0, 0.5); /* Fondo rojo semitransparente para error */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill="white" d="M10.185,0.115c-5.513,0-9.985,4.471-9.985,9.985c0,5.514,4.472,9.985,9.985,9.985 c5.514,0,9.985-4.471,9.985-9.985C20.17,4.586,15.699,0.115,10.185,0.115z M10.185,18.14c-4.395,0-7.961-3.567-7.961-7.961 s3.566-7.961,7.961-7.961s7.961,3.567,7.961,7.961S14.58,18.14,10.185,18.14z M10.185,5.896c-0.246,0-0.446,0.199-0.446,0.446 v4.472c0,0.246,0.199,0.446,0.446,0.446c0.246,0,0.446-0.199,0.446-0.446V6.342C10.631,6.095,10.431,5.896,10.185,5.896z M10.185,13.759c-0.246,0-0.446,0.199-0.446,0.446s0.199,0.446,0.446,0.446s0.446-0.199,0.446-0.446S10.431,13.759,10.185,13.759z"/></svg>');
  background-repeat: no-repeat;
  background-position: 10px center; /* Ajusta la posición del ícono al centro izquierdo */
  background-size: 24px 24px; /* Ajusta el tamaño del ícono */
}

.registration-card {
  max-width: 400px; /* Controla el ancho de la tarjeta */
  margin: 40px auto; /* Centra la tarjeta vertical y horizontalmente */
  background: rgba(0, 0, 0, 0.2); /* Fondo gris transparente */
  padding: 20px;
  border-radius: 10px; /* Bordes redondeados */
}

.registration-title {
  text-align: center; /* Centra el título */
  margin-bottom: 20px;
}

.form-selection {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.form-button {
  margin: 0 10px;
  padding: 5px 15px;
  background-color: #f2f2f2; /* Color de fondo de los botones */
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.form-button:hover {
  background-color: #ddd; /* Cambio de color al pasar el ratón */
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}




.registration-title {
  font-weight: bold; /* Texto en negrita */
  text-align: center; /* Centrar título */
  margin-bottom: 20px; /* Espacio debajo del título */
}

.form-button {
  width: 100%; /* Botón ocupa todo el ancho */
  padding: 10px; /* Espacio dentro del botón */
  margin-bottom: 10px; /* Espacio entre botones */
  border: none; /* Sin bordes */
  border-radius: 5px; /* Bordes redondeados */
  font-weight: bold; /* Texto en negrita */
  cursor: pointer; /* Cambia el cursor */
  transition: background-color 0.3s; /* Transición suave */
}

.form-button.code {
  background-color: #9b59b6; /* Fondo morado */
}

.form-button.code:hover {
  background-color: #8e44ad; /* Fondo morado oscuro al pasar el mouse */
}

.form-button.wallet {
  background-color: #e91e63; /* Fondo rosa */
}

.form-button.wallet:hover {
  background-color: #d81b60; /* Fondo rosa oscuro al pasar el mouse */
}

.form-container {
  margin-top: 20px; /* Espacio arriba del contenedor del formulario */
}
.form-selection {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.form-button {
  background-color: rgba(92, 92, 92, 0.3); /* Amarillo transparente */
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  padding: 5px 15px; /* Ajusta el relleno */
}

.form-button:first-child {
  border-right: 2px solid rgba(173, 173, 173, 0.5); /* Línea divisoria */
}

.form-button:hover {
  background-color: rgba(141, 141, 141, 0.2); /* Amarillo más sólido al pasar el ratón */
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input[type="text"], input[type="email"], input[type="password"] {
  width: 90%; /* Controla el ancho de los inputs */
  padding: 10px; /* Ajusta el relleno interior de los inputs */
  margin-bottom: 10px; /* Espacio entre los inputs */
  border: 1px solid #ccc;
  border-radius: 5px; /* Bordes redondeados para los inputs */
}

.registration-card {
  max-width: 450px; /* Controla el ancho de la tarjeta */
  max-height:550px; /* Controla el ancho de la tarjeta */
  margin-top:20vh;
  margin: 40px auto; /* Centra la tarjeta vertical y horizontalmente */
  background: rgba(0, 0, 0, 0.2); /* Fondo gris transparente */
  background: linear-gradient(rgba(85, 2, 102, 0.3), rgba(0, 45, 245, 0.3)), url(https://raw.githubusercontent.com/CoachblockchainDapp/ImagenesFuturisc/main/6.png); /* Fondo con imagen transparente */
  color: white; /* Color de texto */
  box-shadow: 0px 0px 10px rgba(62, 35, 92, 0.5); /* Sombra ligera */
  background-size: cover; /* Asegura que la imagen cubra todo el fondo */
  background-position: center; /* Centra la imagen en el fondo */
  padding: 20px;
  border-radius: 10px; /* Bordes redondeados */
}

.registration-title {
  font-weight: bold;
  text-align: center;
  padding: 20px; /* Espaciado alrededor del título */
  background: rgba(0, 0, 0, 0.2); /* Fondo semitransparente para el título */
  border-top-left-radius: 10px; /* Bordes redondeados solo en la parte superior */
  border-top-right-radius: 10px;
}

.form-selection {
  display: flex; /* Los botones se muestran en línea */
  margin: 0; /* Elimina los márgenes por defecto */
}

.form-button {
  flex: 1; /* Los botones ocupan todo el espacio disponible */
  padding: 10px;
  margin: 0; /* Elimina el espacio entre botones */
  border: none;
  border-radius: 0; /* Sin bordes redondeados para los botones individuales */
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.form-button:hover, .form-button.active {
  background-color: rgba(200, 157, 56, 0.7); /* Dorado semi-transparente */
  color: white;
}


.form-container {
  padding: 20px; /* Espaciado alrededor del contenido del formulario */
  border-bottom-left-radius: 10px; /* Bordes redondeados solo en la parte inferior */
  border-bottom-right-radius: 10px;
}

.ethCard {
  background-color: #0A5364; /* Un tono de azul */
  padding: 20px;
  border-radius: 10px; /* Bordes redondeados */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra para darle profundidad */
  color: white; /* Texto blanco para contraste */
  margin: 20px 0; /* Añade espacio alrededor de la tarjeta */
  width: fit-content; /* Ajusta el ancho al contenido */
  display: inline-block; /* Hace que la tarjeta no ocupe todo el ancho disponible */
}

.ethPrice, .accountInfo {
  font-size: 1.5em; /* Hace el texto más grande */
  margin-bottom: 10px; /* Espacio entre los elementos de la tarjeta */
  font-weight: bold; /* Hace el texto más grueso */
}

.registration-help {
  font-size: 18px; /* Ajusta al tamaño deseado */
  margin-bottom: 30px; /* Espacio antes del hipervínculo para 'Comprar' */
    width: auto; /* O un valor específico que se ajuste al diseño */
    max-width: 100%; /* Asegura que no se exceda del ancho de la pantalla */
  
}

.link-button {
  color: rgb(7, 214, 241); /* Color azul para el hipervínculo */
  cursor: pointer;
  text-decoration: underline; /* Estilo de hipervínculo */
  display: block; /* Hace que el elemento actúe en su propia línea */
  background-color: transparent;
}