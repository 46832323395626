
.swiper-container {
  position: relative;
  width: 600px;
  height: 600px; 
  margin: auto;
  overflow: hidden;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-wallet {

  padding: 20px;
  background: #fff; 
  border-radius: 20px;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2); 
  margin-bottom: '2%';
}
.card-wallet:hover .content {
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.2);
}

.card-eth {
  width: 195px;
  height: 285px;
  background: #313131;
  border-radius: 20px;
  ;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  transition: 0.2s ease-in-out;
}

.img-eth {
  height: 30%;
  position: absolute;
  transition: 0.2s ease-in-out;
  z-index: 1;
}

.textBox-eth {
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  transition: 0.2s ease-in-out;
  z-index: 2;
}

.textBox-eth > .text {
  font-weight: bold;
}

.textBox-eth > .head {
  font-size: 20px;
}

.textBox-eth > .price {
  font-size: 17px;
}

.textBox-eth > span {
  font-size: 12px;
  color: lightgrey;
}

.card-eth:hover > .textBox-eth {
  opacity: 1;
}

.card-eth:hover > .img-eth {
  height: 65%;
  filter: blur(7px);
  animation: anim 3s infinite;
}

@keyframes anim {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

.card-eth:hover {
  transform: scale(1.04) rotate(-1deg);
}


