.orderContainer-wid {
  width: 98%;
  margin: 0 auto; /* Esto centrará el contenedor en la página */
  padding: 20px; /* Añade un poco de espacio alrededor del contenido interno si lo deseas */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Agrega sombreado */
  border-radius: 10px; /* Redondea los bordes */
}

.orderTitle-wid {
  text-align: center;
  margin-bottom: 20px;
}

.cardsContainer-wid {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Espacio entre tarjetas */
}

.card-wid {
  background-color: white;
  padding: 15px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0px;
  border-left: 3px solid transparent;
  width: 100%; /* Establece el ancho de las tarjetas al 100% */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Agrega sombreado */
  border-radius: 10px; /* Redondea los bordes */
  margin-bottom: 20px; /* Añade espacio entre las tarjetas */
}

.cardDetail-wid {
  text-align: center;
  padding: 10px;
  color: #0A5364;
  font-weight: bold;
  font-size: 14px;
}

.even-wid {
  background-color: #BAD0D4; /* Color de fondo para filas pares */
}

.odd-wid {
  background-color: #7AA2AA; /* Color de fondo para filas impares */
}
