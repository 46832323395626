@import "rsuite/dist/rsuite.css";

#root{ padding: 10px; }



/* Agregar márgenes entre temas y subtemas */
.rs-sidenav-menu .rs-nav-item {
  margin-left: 10px; /* Márgen izquierdo para subtemas */
}

.rs-sidenav-menu .rs-nav-submenu {
  margin-left: 20px; /* Márgen izquierdo para subItems */
}

/* Cambiar el color de fondo de los temas a un gris más oscuro */
.rs-sidenav-menu .rs-nav-item {
    background-color: #2c3e50; /* Color de fondo gris oscuro */
  }

  /* Cambiar el color de fondo cuando se selecciona */
.rs-sidenav-menu .rs-nav-item:hover,
.rs-sidenav-menu .rs-nav-item.rs-nav-item-active {
  background-color: #34495e; /* Color de fondo gris más claro */
}

.nav-title {
  font-weight: bold; /* Makes the text bold */
  font-size: 18px; /* Increases the font size */
  transition: color 0.3s; /* Smooth color transition */
}

.nav-title:hover {
  color: lightblue; /* Changes text color to light blue on hover */
}

/* styles.css */
.rsuite-sidebar {
  scrollbar-width: thin;
  scrollbar-color: #888888 #0A5364; /* Puedes ajustar el color según tus preferencias */
}

.rsuite-sidebar::-webkit-scrollbar {
  width: 5px;
}

.rsuite-sidebar::-webkit-scrollbar-track {
  background: #0A5364; /* Puedes ajustar el color de fondo */
}

.rsuite-sidebar::-webkit-scrollbar-thumb {
  background-color: #888888; /* Puedes ajustar el color del "pulgar" */
  border-radius: 10px;
  border: 1px solid #0A5364; /* Puedes ajustar el color del borde */
}
