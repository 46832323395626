body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(255, 255, 255);
  color: #eee;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nft {
  user-select: none;
  max-width: 500px;
  margin: 5rem auto;
  border: 1px solid #ffffff22;
  background-color: #282c34;
  background: rgb(186, 208, 212);
  box-shadow: 0 7px 20px 5px #00000088;
  border-radius: 0.7rem;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  overflow: hidden;
  transition: 0.5s all;
  hr {
    width: 100%;
    border: none;
    border-bottom: 1px solid #88888855;
    margin-top: 0;
  }
  ins {
    text-decoration: none;
  }
  .main {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 1rem;
    .tokenImage {
      border-radius: 0.5rem;
      max-width: 100%;
      height: 250px;
      object-fit: cover;
    }
    .description {
      margin: 0.5rem 0;
      color: #a89ec9;
    }
    .tokenInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .price {
        display: flex;
        align-items: center;
        color: #ee83e5;
        font-weight: 700;
        ins {
          margin-left: -0.3rem;
          margin-right: 0.5rem;
        }
      }
      .duration {
        display: flex;
        align-items: center;
        color: #a89ec9;
        margin-right: 0.2rem;
        ins {
          margin: 0.5rem;
          margin-bottom: 0.4rem;
        }
      }
    }
    .creator {
      display: flex;
      align-items: center;
      margin-top: 0.2rem;
      margin-bottom: -0.3rem;
      ins {
        color: #a89ec9;
        text-decoration: none;
      }
      .wrapper {
        display: flex;
        align-items: center;
        border: 1px solid #ffffff22;
        padding: 0.3rem;
        margin: 0;
        margin-right: 0.5rem;
        border-radius: 100%;
        box-shadow: inset 0 0 0 4px #000000aa;
        img {
          border-radius: 100%;
          border: 1px solid #ffffff22;
          width: 2rem;
          height: 2rem;
          object-fit: cover;
          margin: 0;
        }
      }
    }
  }
  ::before {
    position: fixed;
    content: "";
    box-shadow: 0 0 100px 40px #ffffff08;
    top: -10%;
    left: -100%;
    transform: rotate(-45deg);
    height: 60rem;
    transition: 0.7s all;
  }
  &:hover {
    border: 1px solid #ffffff44;
    box-shadow: 0 7px 50px 10px #0a5364;
    transform: scale(1.015);
    filter: brightness(1.3);
    ::before {
      filter: brightness(0.5);
      top: -100%;
      left: 200%;
    }
  }
}

.bg {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  h1 {
    font-size: 20rem;
    filter: opacity(0.5);
  }
}

.nft-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nft-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}



.nft-item {
    max-width: 250px;
    margin: 10px;
    width: calc(25% - 20px);
    margin-bottom: 20px;
    position: relative;
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
  }s


.nft-item img {
  border-radius: 0.5rem;
  max-width: 100%;
  height: auto;
  object-fit: cover;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4); /* Agrega sombra a la imagen */
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra horizontalmente */
    text-align: center; /* Centra horizontalmente el texto */
    width: 90%;
    padding: 1rem;
    margin-left: 25px;
  }
  
  .tokenImage {
    border-radius: 0.5rem;
    max-width: 100%;
    height: 250px;
    object-fit: cover;
    margin: 0 auto; /* Centra la imagen horizontalmente */
    margin-left: 50px;
  }
  


button {
    margin-top: 1rem;
    border-radius: 20px;
    background-color: #C89D38;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    display: block; /* Hace que el botón sea un bloque para ocupar toda la línea */
}

#promptContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}





