/* Estilos base para el contenedor principal y los widgets */
.homeContainer {
  max-width: 100%;
  margin: 5% auto; /* Centra el contenedor en la página y añade márgenes superior e inferior */
  padding: 20px; /* Añade padding para evitar que el contenido toque los bordes */
  display: flex;
  flex-direction: column;
  gap: 20px; /* Añade espacio entre los componentes */
  justify-content: center;
  
}

.orderAndMenberWidget {
  display: flex;
  flex-direction: column; /* Los coloca en columna en dispositivos más pequeños */
  gap: 20px; /* Espacio entre widgets */
  align-items: center; /* Centra los widgets */
}

@media (min-width: 768px) {
  .orderAndMenberWidget {
      flex-direction: row; /* Coloca los widgets en fila en pantallas más grandes */
      justify-content: space-between; /* Espacia los elementos uniformemente */
      align-items: flex-start; /* Alinea los elementos al inicio */
  }

  .orderWidget, .menbersWidget {
      width: 100%; /* Asigna a cada widget la mitad del ancho del contenedor, menos un poco para el espacio entre */
      /* Ajusta el porcentaje si necesitas más espacio entre los componentes o si su contenedor padre tiene padding */
  }
}


